import React from "react";
import classNames from "classnames";

const renderParagraph = (block) => {
    const props = { style: {}, className: "" };

    if (block.data.textAlign) {
        props.style["textAlign"] = block.data.textAlign;
    }
    if (block.data.className) {
        props.className = block.data.className;
    }
    return (
        <p
            {...props}
            className={classNames("rte-block-paragraph", props.className)}
            dangerouslySetInnerHTML={{ __html: block.data.text }}
        />
    );
};

const renderDelimiter = () => {
    return <div className="rte-block-delimiter border-b h-1 w-full inline-block my-5 last-of-type:!mb-5" />;
};


const renderHeader = (block) => {
    const props = { style: {}, className: "" };

    if (block.data.textAlign) {
        props.style["textAlign"] = block.data.textAlign;
    }
    if (block.data.className) {
        props.className = block.data.className;
    }

    switch (block.data.level) {
        case 1:
            return (
                <h1
                    {...props}
                    className={classNames(
                        props.className,
                        "rte-block-heading rte-block-heading--h1 text-xl tracking-normal uppercase font-semibold leading-6 mb-2"
                    )}
                    dangerouslySetInnerHTML={{ __html: block.data.text }}
                />
            );

        case 2:
            return (
                <h2
                    {...props}
                    className={classNames(
                        props.className,
                        "rte-block-heading rte-block-heading--h2 text-lg tracking-normal uppercase font-semibold leading-6 mb-2"
                    )}
                    dangerouslySetInnerHTML={{ __html: block.data.text }}
                />
            );

        case 3:
            return (
                <h3
                    {...props}
                    className={classNames(
                        props.className,
                        "rte-block-heading rte-block-heading--h3 text-base tracking-normal uppercase font-semibold leading-6 mb-2"
                    )}
                    dangerouslySetInnerHTML={{ __html: block.data.text }}
                />
            );

        case 4:
            return (
                <h4
                    {...props}
                    className={classNames(
                        props.className,
                        "rte-block-heading rte-block-heading--h4 text-sm tracking-normal uppercase font-semibold leading-6 mb-2"
                    )}
                    dangerouslySetInnerHTML={{ __html: block.data.text }}
                />
            );

        case 5:
            return (
                <h5
                    {...props}
                    className={classNames(
                        props.className,
                        "rte-block-heading rte-block-heading--h5 text-xs tracking-normal uppercase font-semibold leading-6 mb-2"
                    )}
                    dangerouslySetInnerHTML={{ __html: block.data.text }}
                />
            );

        case 6:
            return (
                <h6
                    {...props}
                    className={classNames(
                        props.className,
                        "rte-block-heading rte-block-heading--h6 text-2xl uppercase"
                    )}
                    dangerouslySetInnerHTML={{ __html: block.data.text }}
                />
            );
        default:
            return null;
    }
};

function renderImage(block) {
    return <img className={"rte-block-image my-5"} alt={block.data.caption} src={block.data.file} />;
}

function renderList(block) {
    switch (block.data.style) {
        case "unordered":
            return (
                <ul role="list" className="marker:text-slate-800 list-disc pl-5 space-y-3">
                    {block.data.items.map((text, i) => (
                        <li key={i}>{text}</li>
                    ))}
                </ul>
            );

        case "ordered":
            return (
                <ol role="list" className="marker:text-slate-800 list-disc pl-5 space-y-3">
                    {block.data.items.map((text, i) => (
                        <li key={i}>{text}</li>
                    ))}
                </ol>
            );
        default:
            return null;
    }
}
function renderNestedlist(block) {
    switch (block.data.style) {
        case "unordered":
            return (
                <ul role="list" className="marker:text-slate-800 list-disc pl-5 space-y-3">
                    {block.data.items.map((text, i) => (
                        <li key={i}>{text}</li>
                    ))}
                </ul>
            );

        case "ordered":
            return (
                <ol role="list" className="marker:text-slate-800 list-disc pl-5 space-y-3">
                    {block.data.items.map((text, i) => (
                        <li key={i}>{text}</li>
                    ))}
                </ol>
            );
        default:
            return null;
    }
}

function renderQuote(block) {
    return (
        <blockquote className={"rte-block-blockquote"}>
            <p>{block.data.text}</p>
        </blockquote>
    );
}

function renderCode(block) {
    return (
        <div className="rte-block-code">
            <pre className="text-xs group block mx-full rounded-lg p-6 ring-1 ring-slate-900/5 bg-slate-100 my-3">
            <code>{block.data.code}</code>
            </pre>
        </div>
    );
}

function renderRawhtml(block) {
    return (
        <div className="rte-block-rawhtml" dangerouslySetInnerHTML={{ __html: block.data.html }} />
    );
}


function renderEmbedhtml(block) {
    return (
        <div className="rte-block-embedhtml" dangerouslySetInnerHTML={{ __html: block.data.html }} />
    );
}


const defaultRenderers = {
    delimiter: renderDelimiter,
    header: renderHeader,
    image: renderImage,
    list: renderList,
    nestedlist: renderNestedlist,
    paragraph: renderParagraph,
    quote: renderQuote,
    code: renderCode,
    raw: renderRawhtml,
    embed: renderEmbedhtml,
};

export const RichTextRenderer = props => {    // Combine default renderers with custom renderers
    const renderers = Object.assign({}, defaultRenderers, props.renderers);

    return (
        <React.Fragment>
            {props.content.map((block, index) => {
                const renderer = renderers[block.type];
                if (!renderer) {
                    return null;
                }

                const node = renderer(block);
                if (React.isValidElement(node)) {
                    return React.cloneElement(node, { key: index });
                }

                return null;
            })}
        </React.Fragment>
    );
};